//@flow
import React from 'react';
import { Col, Row, Label } from 'reactstrap';
import { numberFormat } from 'utils';

type Props = {
    commitmentType: string,
    descriptiveStats: object,
};

const DescriptiveStatistics = ({ commitmentType, descriptiveStats }: Props) => {
    const renderNew = () => (
        <Col md={12}>
            IRR:
            {numberFormat(descriptiveStats?.fund_irr * 100)}%; TVM:
            {descriptiveStats?.tvm
                ? `${descriptiveStats?.tvm.toFixed(1)}x`
                : '(no data)'}
            ; Duration:
            {descriptiveStats?.duration
                ? `${descriptiveStats?.duration.toFixed(2)} years`
                : '(no data)'}
            ; Break Even:
            {descriptiveStats?.break_even
                ? `${descriptiveStats?.break_even} years`
                : '(no data)'}
            ;
        </Col>
    );

    const renderExisting = () => (
        <Col md={12}>
            IRR:
            {`${numberFormat(
                descriptiveStats?.fund_irr * 100
            )}% (Default), ${numberFormat(
                descriptiveStats?.existing_fund_irr * 100
            )}% (Projected).`}
            ; TVM:
            {descriptiveStats?.tvm
                ? `${descriptiveStats?.tvm.toFixed(
                      1
                  )}x (Default), ${numberFormat(
                      descriptiveStats?.existing_tvm
                  )}x (Projected).`
                : '(no data)'}
            ; Duration:
            {descriptiveStats?.duration
                ? `${descriptiveStats?.duration.toFixed(2)} years`
                : '(no data)'}
            ; Break Even:
            {descriptiveStats?.existing_break_even
                ? `${descriptiveStats?.existing_break_even} years`
                : '(no data)'}
            ;
        </Col>
    );

    return (
        <div>
            <Row>
                <Col md={12}>
                    <Label style={{ fontWeight: 'bold' }}>
                        Projected Performance
                    </Label>
                </Col>
            </Row>
            <Row>
                {commitmentType === 'new' ? renderNew() : renderExisting()}
            </Row>
        </div>
    );
};

export default DescriptiveStatistics;
