/**
 * This context is for all Auth and user related data/methods
 */

// @flow
import React, { createContext, useState } from 'react';
import type { Node } from 'react';
import Swal from 'sweetalert2';
import queryString from 'query-string';
import {
    attemptExchangeTokenRequest,
    attemptLogOut,
    isAuthenticatedRequest,
    redirectUrl,
} from 'api';

type AuthContextProps = {
    children: Node,
};

export const AuthContext = createContext({});
export const AuthContextConsumer = AuthContext.Consumer;

const AuthContextProvider = ({ children }: AuthContextProps) => {
    const [user, setUser] = useState(false);
    const [exchanging, setExchanging] = useState(false);
    const [status, setStatus] = useState(401);
    const userIsAdmin = user ? user.is_admin : false;
    const userOmniImportAccess = user ? user.omni_import_access : false;
    const userParameterLabAccess = user ? user.parameter_lab_access : false;
    const userSpiClientId = user ? user.spi_client_id : null;
    const trialUserBool = user?.trial_user === 1 ? true : false;
    const isTrialUser = user ? trialUserBool : true;
    const [message, setMessage] = useState('Initializing application.');

    /**
     * Make a request to authorize the user
     * 
     * @param {String} authUrl 
     */
    const attemptExchangeToken = async (authUrl) => {
        const { location } = window;
        const locationValues = queryString.parse(location.search);

        if (
            !locationValues ||
            !locationValues.code ||
            location.pathname !== '/oauth2/exchange'
        ) {
            return false;
        }

        const { code } = locationValues;

        setExchanging(true);
        let tokenResponse = await attemptExchangeTokenRequest(code).catch(
            () => {
                return false;
            }
        );

        if (!tokenResponse) {
            setExchanging(false);
            return false;
        }

        const { authenticated } = tokenResponse;

        if (!authenticated) {
            tokenResponse = await isAuthenticatedRequest(code).catch(() => {
                window.location = authUrl + redirectUrl;
                return false;
            });
        }

        setExchanging(false);
        setUser(tokenResponse);

        return true;
    };

    /**
     * Handle if authorization passes/fails
     * 
     * @param {String} authUrl 
     */
    const checkExchangeToken = async (authUrl) => {
        let exchangeTokenSuccessful;
        try {
            exchangeTokenSuccessful = await attemptExchangeToken(authUrl);
        } catch (e) {
            window.location = authUrl + redirectUrl;
            return;
        }

        if (!exchangeTokenSuccessful) {
            // redirects to login page
            window.location = authUrl + redirectUrl;
        }
    };

    /**
     * Check if already authenticated, else
     * request exchange token
     * 
     * @param {Boolean} isLogout 
     */
    const checkAuthentication = async (isLogout = false) => {
        let authorizedUser;

        try {
            const newMessage = exchanging
                ? 'Please wait while we log you in.'
                : 'Redirecting you to our authentication server.';

            setMessage(newMessage);

            authorizedUser = await isAuthenticatedRequest();
        } catch (e) {
            const { status } = e;
            if (status === 401) {
                const { auth_url: authUrl } = e.responseJSON;
                if (isLogout) {
                    const identityUrl = authUrl.substr(
                        0,
                        authUrl.indexOf('oauth/authorize')
                    );
                    window.location = `${identityUrl}oauth/logout`;
                    return;
                }

                return checkExchangeToken(authUrl);
            } else if (status === 403) {
                setMessage(
                    'Access has expired. Please email pacing@stepstonegroup.com if you believe this is an error.'
                );
            }
            setUser(false);
            return undefined;
        }

        setUser(authorizedUser);
        return true;
    };

    /**
     * Log out of app
     */
    const logOut = async () => {
        Swal.fire({
            html: 'Logging out',
            allowOutsideClick: false,
            allowEnterKey: false,
            allowEscapeKey: false,
            showConfirmButton: false,
            showCancelButton: false,
            showCloseButton: false,
        });

        Swal.showLoading();

        try {
            await attemptLogOut();

            await checkAuthentication(true);
        } catch (e) {
            Swal.fire(
                'Uh oh',
                'Something went wrong, please contact an administrator.',
                'error'
            );
        }
    };

    const isInternal = () => {
        // if user email is in the domain of stepstonegroup then is internal
        return (user?.email || '').includes('@stepstonegroup.com')
    }

    const isClient = () => {
        return !isInternal();
    }

    return (
        <AuthContext.Provider
            value={{
                checkAuthentication,
                logOut,
                exchanging,
                isTrialUser,
                message,
                status,
                user,
                userIsAdmin,
                userOmniImportAccess,
                userParameterLabAccess,
                userSpiClientId,
                isClient,
                isInternal,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContextProvider;
