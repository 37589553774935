import React, { useContext, useEffect, useRef, useState } from 'react';
import { Grid, TableVariant } from '@ssgglobal/techintnue'
import Swal from 'sweetalert2';

import { checkConversusAccess } from 'utils';
import { AuthContext, LandingContext, StaticsContext, PacingAnalysisContext } from 'context';

import {
    UnderwritingFrameworkComponents,
} from '../../components';
import columnDefinition from './columnDefinition';
import useExistingCommitmentsGrid from './hooks'

const ExistingCommitmentGrid = ({
    onSelected,
    selected,
    showLiquidatedFunds,
    scenario = 'Base',
}) => {
    const gridApi = useRef();
    const { reportDate } = useContext(LandingContext);
    const { user, userIsAdmin, isClient } = useContext(AuthContext);
    const { targetAllocationStrategies } = useContext(StaticsContext);
    const { checkForRenamedStrategy, findStrategyByName } = useContext(PacingAnalysisContext);
    const isExternal = isClient();

    const {
        data,
        updateValue,
        resetRow,
        emptyUpdatedAfterSort,
        showSAAOptions,
        loading,
        // handle paste
        onPasteStart,
        onPasteEnd,
        onCellValueChanged,
        processDataFromClipboard,
    } = useExistingCommitmentsGrid({
        api: gridApi?.current?.api,
        selected,
    })
    const conversusAccess = checkConversusAccess(user);
    const frameworkComponents = UnderwritingFrameworkComponents();

    useEffect(() => {
        if (loading) {
            Swal.showLoading();
            return;
        }
        Swal.close();
    }, [loading])

    const calculateSizes = () => {
        if (gridApi && gridApi.current && gridApi.current.api) {
            const { gridBodyCtrl: panel } = gridApi.current.api;
            if (panel && panel) {
                const {
                    eBodyViewport: {
                        clientWidth: availableWidth,
                    },
                    columnModel: {
                        displayedColumns,
                        getWidthOfColsInList,
                    }
                } = panel;
                const usedWidth = getWidthOfColsInList(displayedColumns);
                if (usedWidth < availableWidth) {
                    gridApi.current.api.sizeColumnsToFit();
                }
            }
        }
    }

    const handlePostSortRows = (params) => {
        const { nodes: rowNodes } = params;
        const sorted = rowNodes.filter((node) => node.data.lastUpdated).sort((a, b) => {
            return a.data.lastUpdated.index - b.data.lastUpdated.index
        })

        sorted.forEach((node) => {
            const { lastUpdated } = node.data;
            if (lastUpdated !== undefined) {
                // should move the node to rowIndex
                const rowIndex = lastUpdated.index;
                const oldIndex = rowNodes.findIndex((oldNode) => (
                    oldNode.data.id === node.data.id
                    && oldNode.data.scenario === node.data.scenario
                ));
                if (oldIndex || oldIndex === 0) {
                    // if found index
                    const foundNode = rowNodes.splice(oldIndex, 1)[0] // removes the current node from list
                    rowNodes.splice(
                        rowIndex, // index where to add
                        0, // dont remove nothing
                        foundNode, // it add the node again in the correct position
                    );
                }
            }
        })

    }

    // set fitlers on filter changed
    useEffect(() => {
        if (gridApi.current && gridApi.current.api) {
            const fundStatusFilter = gridApi.current.api.getFilterInstance('fundStatus');
            if (fundStatusFilter) {
                if (!showLiquidatedFunds) {
                    fundStatusFilter.setModel({
                        type: 'set',
                        values: ['Active'],
                    });
                    // show only actives
                } else {
                    // remove filter to show all funds
                    fundStatusFilter.setModel({});
                }
            }
    
            const scenarioFilter = gridApi.current.api.getFilterInstance('scenario');
            if (scenarioFilter) {
                if (scenario !== 'All') {
                    scenarioFilter.setModel({
                        type: 'set',
                        values: [scenario],
                    });
                } else {
                    scenarioFilter.setModel({});
                }
            }
            gridApi.current.api.onFilterChanged();
        }
    }, [showLiquidatedFunds, scenario]);

    const columnDefs = columnDefinition(
        updateValue,
        resetRow,
        conversusAccess,
        reportDate,
        targetAllocationStrategies,
        userIsAdmin,
        checkForRenamedStrategy,
        showSAAOptions,
        isExternal,
    );

    const handleOnSelectedRow = (event) => {
        if (!event.node.selected)
            return;
        let { data } = event;
        // check to rename
        const strategy = findStrategyByName(event.data.strategy);
        if (strategy && strategy.strategy) {
            // update strategy to the original name if needed
            data.strategy = strategy.strategy;
        }
        onSelected(data);
    }

    return (
        <Grid
            sx={{
                height: '500px',
                '& .ag-body-viewport': {
                    '& > *': {
                        minHeight: '100% !important'
                    },
                    border: 'var(--ag-borders) var(--ag-border-color)',
                }
            }}
        >
            <TableVariant
                gridStyle={{ height: '500px' }}
                ref={gridApi}
                tableType="aggrid"
                minHeight="500px"
                agTheme="ag-theme-balham"
                className="ag-grid-custom"
                agGridOptions={{
                    height: '500px',
                    rowSelection: 'single',
                    defaultColDef: {
                        resizable: true,
                        filter: true,
                        sortable: true,
                    },
                    onPasteStart,
                    processDataFromClipboard,
                    onCellValueChanged,
                    onPasteEnd,
                    columnDefs: columnDefs,
                    rowData: [...data] || [],
                    components: frameworkComponents,
                    onRowSelected: handleOnSelectedRow,
                    singleClickEdit: true,
                    rowSelection: 'single',
                    stopEditingWhenCellsLoseFocus: true,
                    suppressScrollOnNewData: true,
                    // suppressMenuHide: true,
                    onFirstDataRendered: (event) => {
                        event.api.selectIndex(0, false);
                    },
                    postSortRows: handlePostSortRows,
                    onSortChanged: (params) => {
                        if (params.source === "uiColumnSorted") {
                            emptyUpdatedAfterSort();
                        }
                    },
                    onGridReady: (event) => {
                        calculateSizes();
                        const fundStatusFilter = event.api.getFilterInstance('fundStatus');
                        if (fundStatusFilter) {
                            if (!showLiquidatedFunds) {
                                fundStatusFilter.setModel({
                                    type: 'set',
                                    values: ['Active'],
                                });
                                // show only actives
                            } else {
                                // remove filter to show all funds
                                fundStatusFilter.setModel({});
                            }
                        }
                        const scenarioFilter = event.api.getFilterInstance('scenario');
                        if (scenarioFilter) {
                            if (scenario !== 'All') {
                                scenarioFilter.setModel({
                                    type: 'set',
                                    values: [scenario],
                                });
                            } else {
                                scenarioFilter.setModel({});
                            }
                        }
                        event.api.onFilterChanged();
                    }
                }}
            />
        </Grid>
    )
};

export default ExistingCommitmentGrid;
