import { useEffect, useState, useContext } from 'react';
import { UnderwritingContext, StaticsContext, LandingContext } from 'context';
import { getDataRow } from './createDataRow'

// const values
const mapValues = {
    'Base': 'values',
    'High': 'valuesHigh',
    'Low': 'valuesLow',
}

const useExistingCommitmentsGrid = ({ api, selected }) => {
    const [data, setData] = useState([]);
    const [updatedAfterSort, setUpdatedAfterSort] = useState([]);
    const [loading, setLoading] = useState(false);
    const {
        fundSliderValues,
        updateFundSliderValueByKey,
        updateFundSlider,
        resetFundSliderValue,
    } = useContext(UnderwritingContext);
    const { strategyParameters } = useContext(StaticsContext);
    const { reportDate, updateTableDataValueByKey } = useContext(LandingContext)
    const reportDateParts = reportDate.split('-');
    const measureDate = new Date(
        reportDateParts[0],
        reportDateParts[1] - 1,
        reportDateParts[2]
    );
    const showSAAOptions = fundSliderValues
        .filter((row) => !!row.saaInvestmentType)
        .length > 0;

    // handle copy/pasting
    const onPasteStart = () => setLoading(true);
    const onPasteEnd = () => setLoading(false);
    const processDataFromClipboard = ({ data }) => {
        let parseData = data;
        const reduceIsEmptyRow = (accumulator, currentValue) => (
            accumulator || !!currentValue
        );
        let stop = false;
        while (!stop && parseData.length > 0) {
            const empty = !parseData[parseData.length - 1].reduce(reduceIsEmptyRow, false);
            if (empty) {
                // remove last item
                parseData.pop()
            } else {
                stop = true;
            }
        }
        return parseData;
    };
    const onCellValueChanged = async (event) => {
        if (event.source === 'paste') {
            // force update on paste
            await updateValue(event.value, event.column.colId, event.node.data, true)
        }
    };

    const emptyUpdatedAfterSort = () => {
        setUpdatedAfterSort([])
    };

    useEffect(() => {
        if (loading) return; // don't update
        const newData = [];
        fundSliderValues.forEach((fund) => {
            const {
                values: base,
                valuesHigh: high,
                valuesLow: low,
            } = fund;

            newData.push({
                ...getDataRow(
                    fund,
                    base,
                    'Base',
                    strategyParameters,
                    measureDate,
                ),
                // meta data for re sort if edit
                lastUpdated: updatedAfterSort.find((row) => (
                    row.id === fund.id
                    && row.scenario === 'Base'
                )),
            });
            newData.push({
                ...getDataRow(
                    fund,
                    high,
                    'High',
                    strategyParameters,
                    measureDate,
                ),
                // meta data for re sort if edit
                lastUpdated: updatedAfterSort.find((row) => (
                    row.id === fund.id
                    && row.scenario === 'High'
                )),
            });
            newData.push({
                ...getDataRow(
                    fund,
                    low,
                    'Low',
                    strategyParameters,
                    measureDate,
                ),
                // meta data for re sort if edit
                lastUpdated: updatedAfterSort.find((row) => (
                    row.id === fund.id
                    && row.scenario === 'Low'
                )),
            });
        });
        setData(newData);
    }, [fundSliderValues, updatedAfterSort, loading]);

    const resetRow = ({ id, strategy, scenario }) => {
        resetFundSliderValue({
            fundId: id,
            strategy,
            scenario,
        });
    }

    useEffect(() => {
        if (api) {
            // update selection
            const selection = api.getSelectedRows();
            if (!selection || selection.length === 0) {
                // if there is no row selected, select current
                api.forEachNode((node) => {
                    if (
                        node.rowIndex !== null                       // if is visible
                        && node.data.id === selected.id              // if it's the selected row
                        && node.data.scenario === selected.scenario  // if it's the same scenario
                    ) {
                        node.setSelected(true, true);
                    }
                });
            };
        }
    }, [data]);

    const updateValue = async (newValue, column, row, force=false) => {
        let value = newValue;
        const oldValue = row[column];
        if (
            // if not force
            !force
            // if no change do nothing
            && newValue === oldValue
        ) return;

        // add meta data to sort first of doing anything
        if (api) {
            let foundIndex = null;
            api.forEachNode((node, i) => {
                if (
                    node.rowIndex !== null                  // if is visible
                    && node.data.id === row.id              // if it's the updated row
                    && node.data.scenario === row.scenario  // if it's the same scenario
                ) {
                    foundIndex = node.rowIndex;
                }
            })
            if (foundIndex !== null) {
                setUpdatedAfterSort([
                    ... new Set([
                        ...updatedAfterSort,
                        { id: row.id, fundId: row.fundId, scenario: row.scenario, index: foundIndex },
                    ])
                ]);
            }
        }

        if (['closingYear', 'commitQtr'].includes(column)) {
            // this should update only the funds? or it should be by case?
            updateFundSlider({ id: row.id, [column]: newValue });
            return;
        }
        if (['strategy', 'contribTiming'].includes(column)) {
            if (column === 'strategy') {
                // update table data
                updateTableDataValueByKey(row.id, 'PacingStrategy', newValue);
            }
            // should reset fund and update
            resetFundSliderValue({ fundId: row.id, strategy: row.strategy, contribTiming: row.contribTiming });
        }
        if ([
            'paidIn',
            'rc1',
            'rc2',
            'rc3',
            'rc4',
            'rc5',
            'irr',
            'yld',
        ].includes(column)) {
            // format to percentage
            value = parseFloat(value) / 100.0;
        }

        updateFundSliderValueByKey(row.id, column, value, row.scenario);
    }

    return {
        data,
        updateValue,
        resetRow,
        emptyUpdatedAfterSort,
        showSAAOptions,
        loading,
        // handle copy/paste
        onPasteStart,
        onPasteEnd,
        processDataFromClipboard,
        onCellValueChanged,
    }
}

export default useExistingCommitmentsGrid;
