import { filterStrategyAllocationByAssetClass } from 'utils';

function formatFundPset(fund, defaults) {
    let holder = [];
    let scenarioValue = '';

    if (defaults.case === 'Base') {
        scenarioValue = 'values';
    } else if (defaults.case === 'High') {
        scenarioValue = 'valuesHigh';
    } else if (defaults.case === 'Low') {
        scenarioValue = 'valuesLow';
    }

    if (fund[scenarioValue] !== '' && fund[scenarioValue]) {
        holder = [
            parseFloat(fund[scenarioValue].paidIn),
            parseFloat(fund[scenarioValue].irr),
            parseFloat(fund[scenarioValue].yld),
            parseFloat(fund[scenarioValue].fundLife),
            parseFloat(fund[scenarioValue].bow),
            parseFloat(fund[scenarioValue].rc1),
            parseFloat(fund[scenarioValue].rc2),
            parseFloat(fund[scenarioValue].rc3),
            parseFloat(fund[scenarioValue].rc4),
            parseFloat(fund[scenarioValue].rc5),
            parseInt(fund[scenarioValue].coinvestFlag, 10) || 0,
            fund[scenarioValue].commitQtr || 'Q2',
            parseInt(fund[scenarioValue].numFunds, 10) || 1,
        ];
    } else {
        holder = [
            parseFloat(defaults.paidIn),
            parseFloat(defaults.irr),
            parseFloat(defaults.yld),
            parseFloat(defaults.fundLife),
            parseFloat(defaults.bow),
            parseFloat(defaults.rc1),
            parseFloat(defaults.rc2),
            parseFloat(defaults.rc3),
            parseFloat(defaults.rc4),
            parseFloat(defaults.rc5),
            parseInt(defaults.coinvestFlag, 10) || 0,
            'Q2',
            defaults.numFunds,
        ];
    }

    return holder;
}

export function formatTableData(tableData, fundSliderValues) {
    const Investment = [];
    const PacingStrategy = [];
    const Vintage = [];
    const AssetClass = [];
    const InvestmentType = [];
    const Strategy = [];
    const SubStrategy = [];
    const Geography = [];
    const Commitment = [];
    const Contributions = [];
    const Distributions = [];
    const AdjNAV = [];
    const Unfunded = [];
    const IRR = [];
    const TVM = [];
    const ForwardNAV = [];
    const ClosingDate = [];
    
    tableData.forEach((row) => {
        const fund = fundSliderValues.find(({ id }) => row.Id === id);
        if (fund?.closingYear) {
            Vintage.push(Number(fund.closingYear));
        } else {
            row?.ClosingDate ? Vintage.push(Number(row.ClosingDate.substring(0,4))) : Vintage.push(Number(row.Vintage));
        }

        Investment.push(row.Investment);
        PacingStrategy.push(fund.values.strategy);
        AssetClass.push(row.AssetClass);
        InvestmentType.push(row.InvestmentType);
        Strategy.push(row.Sector);
        SubStrategy.push(row.SubSector);
        Geography.push(row.Geography);
        Commitment.push(Number(row.Commitment));
        Contributions.push(Number(row.Contributions));
        Distributions.push(Number(row.Distributions));
        AdjNAV.push(Number(row.AdjNAV));
        Unfunded.push(Number(row.Unfunded));
        IRR.push(parseFloat(row.IRR) || 0);
        TVM.push(parseFloat(row.TVM) || 0);
        ForwardNAV.push(parseFloat(row.ForwardNAV) || 0);
        ClosingDate.push(row.ClosingDate);
    });

    return {
        Investment,
        PacingStrategy,
        Vintage,
        AssetClass,
        InvestmentType,
        Strategy,
        SubStrategy,
        Geography,
        Commitment,
        Contributions,
        Distributions,
        AdjNAV,
        Unfunded,
        IRR,
        TVM,
        ForwardNAV,
        ClosingDate
    };
}

/**
 * Format the data to send via Estimate Commitments
 * or Run Analysis
 */
export function formatCommitSchedule(
    pacingAnalysisContext,
    userDefinedRenamedTAS,
    targetExposureType,
    noStrategies,
    reportDate
) {
    const {
        pacingParameters: {
            commitmentSchedule,
            startYear,
            endYear,
            fiscalYearPlusOne,
            commitmentScheduleOptimizer,
        },
    } = pacingAnalysisContext;
    // Create the empty data object
    const formattedSchedule = { Strategy: [], userEdited: [] };
    const formattedOptimizer = { Strategy: [] };
    const thisYear = reportDate ? new Date(reportDate).getFullYear() : new Date().getFullYear();
    let validStartYear;
    if (targetExposureType === 'noNewCommitments') {
        validStartYear = thisYear;
    } else if (noStrategies) {
        validStartYear = thisYear;
    } else {
        validStartYear = startYear;
    }

    for (let i = validStartYear; i <= endYear; i++) {
        const year = fiscalYearPlusOne ? i + 1 : i;
        if (year <= endYear) {
            formattedSchedule[year] = [];
            formattedOptimizer[year] = [];
        }
    }

    const commitConstraints = {
        min: [],
        max: [],
        increment: [],
    }

    // iterate through comitmentSchedule and fill in data object
    commitmentSchedule.forEach((row) => {
        Object.keys(row).forEach((key) => {
            let holderKey = key;
            // ignore columns not needed
            if (key === 'alloc' || key === 'serverNames' || key === 'commitQtr') {
                return;
            }
            if (key.includes('_1')) {
                holderKey = key.slice(0, key.length - 2);
            }
            if (key === 'strategy') {
                formattedSchedule.Strategy.push(row[key]);
                if (!('userEdited' in row)){
                    formattedSchedule.userEdited.push([]);
                }
            } else if (key !== 'min' && key !== 'max' && key !== 'increment' && key !== 'userEdited') {
                formattedSchedule[holderKey].push(Number(row[key]));
            } else if (key === 'userEdited') {
                formattedSchedule.userEdited.push(row[holderKey]);
            } else {
                commitConstraints[key].push(Number(row[key]));
            } 
        });
    });

    // iterate through optimizer and fill out data object
    commitmentScheduleOptimizer.forEach((row) => {
        Object.keys(row).forEach((key) => {
            let holderKey = key;
            if (key.includes('_1')) {
                holderKey = key.slice(0, key.length - 2);
            }
            if (key === 'strategy') {
                formattedOptimizer.Strategy.push(row[key]);
            } else if (key !== 'min' && key !== 'max' && key !== 'increment') {
                // if already exists
                if (formattedOptimizer[holderKey]) {
                    formattedOptimizer[holderKey].push(Number(row[key]));
                }
            }
        });
    });

    // Check if any of the strategies to be sent are user defined
    // Set value to original value if user defined
    Object.keys(formattedSchedule).forEach((key) => {
        if (key === 'Strategy') {
            formattedSchedule[key].forEach((stratName, index) => {
                let value = stratName;
                Object.keys(userDefinedRenamedTAS).forEach((wName) => {
                    let valueToCheck = '';
                    let valueToSend = '';
                    const {
                        prefix,
                        currentValue,
                        originalValue,
                    } = userDefinedRenamedTAS[wName];
                    valueToCheck = `${prefix} ${currentValue}`;
                    valueToSend = `${prefix} ${originalValue}`;
                    if (valueToCheck === stratName) {
                        value = valueToSend;
                    }
                });

                formattedSchedule[key][index] = value;
                formattedOptimizer[key][index] = value;
            });
        }
    });

    // Handle case of no new commitments or no strategies.
    // Set to default object with one strat (back-end will handle)
    // Back-end will error if empty object is sent
    if (targetExposureType === 'noNewCommitments' || noStrategies) {
        Object.keys(formattedSchedule).forEach((key) => {
            if (key === 'Strategy') {
                formattedSchedule.Strategy = ['PE Buyouts'];
            } else {
                formattedSchedule[key] = [0];
            }
        });
    }

    return {
        formattedCommitmentSchedule: formattedSchedule,
        formattedOptimizer,
        commitConstraints,
    };
}

const formatDataToArray = (array, fiscalYearPlusOne, startYear, strategy) => {
    const formattedData = {};
    let currentYear = fiscalYearPlusOne ? startYear + 1 : startYear;

    array.forEach((item) => {
        formattedData[currentYear] = item;
        currentYear++;
    });

    formattedData.strategy = strategy;

    return formattedData;
};

/**
 * Format the data to send via Estiamte Commitments
 * or Run Analysis
 */
export function formatPortfolioAumAndLiquidAssets(
    portfolioAumData,
    liquidAssetData,
    startYear,
    fiscalYearPlusOne
) {
    const formattedPortfolioAum = formatDataToArray(
        portfolioAumData,
        fiscalYearPlusOne,
        startYear,
        'Portfolio AuM'
    );

    const formattedData = [formattedPortfolioAum];

    if (liquidAssetData) {
        const formattedLiquidAssets = formatDataToArray(
            liquidAssetData,
            fiscalYearPlusOne,
            startYear,
            'Liquid Assets'
        );

        formattedData.push(formattedLiquidAssets);
    }

    return formattedData;
}

export function formatPsetList(
    psetList,
    fundPsetList,
    tableData,
    strategyParameters,
    reportDate
) {
    let psetListCopy = [...psetList];

    // Set default psets if not done already
    if (psetListCopy.length === 0) {
        psetListCopy = strategyParameters;
    }

    // Set default fund psets if not done already
    tableData.forEach((row) => {
        // Find the default params for the table row's strategy
        const strategyDefaults = strategyParameters.find(
            (stratDefault) =>
                stratDefault.strategy === row.PacingStrategy &&
                stratDefault.case === 'Base' &&
                stratDefault.contribTiming === 'Base' &&
                stratDefault.age ? stratDefault.age === row.age : true
        );

        let value = 100;
        // Find existing pset for table row
        const foundFundSliders = fundPsetList.find((fundSlider, index) => {
            value = index;
            return row.Id === fundSlider.id;
        });
        // If no existing pset is found, assign the default params
        if (!foundFundSliders) {
            if (strategyDefaults) {
                fundPsetList.push({
                    investment: row.Investment,
                    values: {
                        strategy: strategyDefaults.strategy,
                        model: strategyDefaults.model.trim(),
                        paidIn: parseFloat(strategyDefaults.paidIn),
                        irr: parseFloat(strategyDefaults.irr),
                        yld: parseFloat(strategyDefaults.yld),
                        fundLife: parseFloat(strategyDefaults.fundLife),
                        bow: parseFloat(strategyDefaults.bow),
                        rc1: parseFloat(strategyDefaults.rc1),
                        rc2: parseFloat(strategyDefaults.rc2),
                        rc3: parseFloat(strategyDefaults.rc3),
                        rc4: parseFloat(strategyDefaults.rc4),
                        rc5: parseFloat(strategyDefaults.rc5),
                        age: parseInt(strategyDefaults.age, 10),
                        userEdited: strategyDefaults.userEdited
                    },
                });
            }
        } else if (row.Investment !== foundFundSliders.investment) {
            // eslint-disable-next-line no-param-reassign
            fundPsetList[value].investment = row.Investment;
        } else {
            // if there is foundFundSlider use that new data
            fundPsetList.splice(value, 1);
            fundPsetList.push({
                investment: row.Investment,
                values: {
                    ...foundFundSliders.values,
                    strategy: foundFundSliders.values.strategy,
                    model: strategyDefaults.model.trim() || 'Yale',
                },
            });
        }
    });

    const formattedList = {};
    // First create an empty formattedList
    psetListCopy.forEach((sliderValue) => {
        formattedList[sliderValue.strategy] = {
            [sliderValue.model.trim()]: {
                New: {
                    base: [],
                    high: [],
                    low: [],
                },
            },
        };
    });

    // Fill out formattedList by iterating through sliderValues
    psetListCopy.forEach((sliderValue) => {
        const holderArray = [
            parseFloat(sliderValue.paidIn),
            parseFloat(sliderValue.irr),
            parseFloat(sliderValue.yld),
            parseFloat(sliderValue.fundLife),
            parseFloat(sliderValue.bow),
            parseFloat(sliderValue.rc1),
            parseFloat(sliderValue.rc2),
            parseFloat(sliderValue.rc3),
            parseFloat(sliderValue.rc4),
            parseFloat(sliderValue.rc5),
        ];

        // if coinvest flag exists
        if (Object.prototype.hasOwnProperty.call(sliderValue, 'coinvestFlag')) {
            holderArray.push(sliderValue.coinvestFlag);
        } else {
            // Find the default value
            // Only need to find the first one. All record should have the same co-invest flag
            const defaultSliderValues = strategyParameters.find((param) => {
                return param.strategy === sliderValue.strategy;
            });

            holderArray.push(defaultSliderValues.coinvestFlag);
        }

        // Push commitment timing after coinvest flag
        // Special logic for PD Platform and Platform-Levered
        let commitmentQuarter = sliderValue.commitQtr || 'All';
        commitmentQuarter =
            sliderValue.strategy === 'PD Private Debt Platform' ||
            (sliderValue.strategy === 'PD Private Debt Platform-Levered' &&
                !sliderValue.commitQtr)
                ? 'All'
                : commitmentQuarter;

        holderArray.push(commitmentQuarter);
        holderArray.push(parseInt(sliderValue.numFunds, 10) || 5);

        let scenario = 'base';
        if (sliderValue.case === 'High') {
            scenario = 'high';
        } else if (sliderValue.case === 'Low') {
            scenario = 'low';
        }

        formattedList[sliderValue.strategy][sliderValue.model.trim()].New = {
            ...formattedList[sliderValue.strategy][sliderValue.model.trim()]
                .New,
            [scenario]: holderArray,
        };
    });

    // Iterate through fundPsetList and add the fund's strategy
    // to formatted list if it doesn't already exist
    // For cases where there's an existing commitments strategy
    // that isn't used in future commitments
    fundPsetList.forEach((fund) => {
        if (Object.prototype.hasOwnProperty.call(fund, 'values')) {
            const {
                values: { strategy, model },
                investment,
            } = fund;

            const defaultStrats = strategyParameters.filter(
                (strat) =>
                    strat.strategy === strategy &&
                    strat.contribTiming === 'Base'
            );

            // Add strategy to formattedList if it doesn't exist
            if (
                !Object.prototype.hasOwnProperty.call(formattedList, strategy)
            ) {
                formattedList[strategy] = { [model.trim()]: {} };
            }

            // handle cases where we've removed a strategy from the list
            // of acceptable strategies, but it still exists in a saved pacing
            if (!defaultStrats) {
                return;
            }

            let baseHolder = [];
            let highHolder = [];
            let lowHolder = [];

            defaultStrats.forEach((defaults) => {
                const holder = formatFundPset(fund, defaults);
                if (defaults.case === 'Base' && defaults.numFunds === 1) {
                    baseHolder = holder;
                } else if (
                    defaults.case === 'High' &&
                    defaults.numFunds === 1
                ) {
                    highHolder = holder;
                } else if (defaults.case === 'Low' && defaults.numFunds === 1) {
                    lowHolder = holder;
                }
            });

            formattedList[strategy][model.trim()][investment] = {
                base: baseHolder,
                high: highHolder,
                low: lowHolder,
            };
        }
    });

    return formattedList;
}

export function formatInputs(
    pacingAnalysisContext,
    targetAllocationContext,
    targetAllocationStrategies,
    smoothing,
    assetClassShortNames,
    reportDate,
    useForwardNav,
    type = 'analysis'
) {
    const {
        growthIncrement,
        growthOnLiquidAssets,
        growthType,
        increment,
        pacingParameters: {
            dateType,
            endYear,
            feesOnNav,
            fiscalMonth,
            multipleGrowthPercents,
            portfolioAsOf,
            privateMarketExposure,
            singleGrowthPercent,
            scenario,
            targetYear,
            targetYearType,
            totalAum,
            dtStartQtr,
            dtStartYear,
            dtEndQtr,
            dtEndYear,
            dtPercent,
            downturnContribEndDate = '',
            downturnContribPercentage,
            downturnContribActive,
            downturnDistribEndDate = '',
            downturnDistribPercentage,
            downturnDistribActive,
        },
        portfolioContributions,
    } = pacingAnalysisContext;
    const {
        exposure: { pe, pd, re, ra },
        strategyAllocation,
        targetAllocation: { currency, targetExposureType },
        userDefinedRenamedTAS,
    } = targetAllocationContext;

    let {
        pacingParameters: { quarter, startYear },
    } = pacingAnalysisContext;

    if (targetExposureType === 'noNewCommitments') {
        startYear = 0;
        quarter = '';
    }
    const noStrategies = strategyAllocation.filter(({ selected }) => selected).length === 0

    const contrib = [];
    const thisYear = reportDate ? new Date(reportDate).getFullYear() : new Date().getFullYear();
    let validStartYear;
    if (targetExposureType === 'noNewCommitments') {
        validStartYear = thisYear;
    } else if (noStrategies) {
        validStartYear = thisYear;
    } else {
        validStartYear = startYear;
    }

    for (let i = validStartYear; i <= endYear; i++) {
        const valueToPush = portfolioContributions[i]
            ? parseFloat(portfolioContributions[i])
            : 0;
        contrib.push(valueToPush);
    }

    let portGrowth;
    if (growthType === 'multiple') {
        portGrowth = [];

        Object.keys(multipleGrowthPercents).forEach((key) => {
            if (key >= startYear && key <= endYear && key !== 'strategy') {
                portGrowth.push(parseFloat(multipleGrowthPercents[key]));
            }
        });
    } else if (growthType === 'single' && type === 'analysis') {
        portGrowth = singleGrowthPercent;
    } else {
        // this will format it nicely for the excel doc
        portGrowth = singleGrowthPercent / 100;
    }

    const newAumDate = portfolioAsOf === '' ? reportDate : portfolioAsOf;

    const [downturnContribEndQtr, downturnContribEndYear] = downturnContribEndDate.split(" ");
    const [downturnDistribEndQtr, downturnDistribEndYear] = downturnDistribEndDate.split(" ");
    const activeScenario = downturnContribActive || downturnDistribActive;
    // if contrib scenario not active set percentage to 0
    const scenarioContribPercentage = downturnContribActive ? downturnContribPercentage : 0;
    // if distrib scenario not active set percentage to 0
    const scenarioDistribPercentage = downturnDistribActive ? downturnDistribPercentage : 0;
    const contribScenarioEndYr = scenarioContribPercentage === 0 ? parseInt(validStartYear) : downturnContribEndYear;
    const distribScenarioEndYr = scenarioDistribPercentage === 0 ? parseInt(validStartYear) : downturnDistribEndYear;

    const inputs = {
        performance_case: scenario,
        alpha: feesOnNav,
        aumDate: newAumDate,
        contrib,
        currency,
        // change exposurestyle in the future to boolean for a more logical implementation ()
        exposurestyle: targetExposureType === 'currency' ? '2' : '1', // needs to be a string, not number
        fiscalYE: fiscalMonth,
        growthincrement: growthIncrement,
        gtoLiquid: growthOnLiquidAssets,
        Increment: parseInt(increment, 10) || 5,
        lastYear: endYear,
        measureDate: reportDate !== '' ? reportDate : '2019-03-31', // default to any date. Not used when no existing commitments, but will throw error if blank.
        percentPrivateMarketsExposure: Number(privateMarketExposure) / 100, // needs to be a number, not string
        portsize: totalAum,
        portgrowth: portGrowth,
        useFiscal: dateType,
        rolldate: reportDate,
        rollforward: useForwardNav,
        smooth: smoothing,
        startqtr: quarter,
        startyear: startYear,
        usetarget: targetYearType === 'optimize' ? false : Number(targetYear),
        // PEexposure: targetExposureType === 'noNewCommitments' ? 100 : pe,
        // PDexposure: targetExposureType === 'noNewCommitments' ? 0 : pd,
        // REexposure: targetExposureType === 'noNewCommitments' ? 0 : re,
        // RAexposure: targetExposureType === 'noNewCommitments' ? 0 : ra,
        // downturn_startqtr: dtStartQtr || 'Q1',
        // downturn_startyear: dtStartYear || 2023,
        // downturn_endqtr: dtEndQtr || 'Q4',
        // downturn_endyear: dtEndYear || 2023,
        // downturn_pct: dtPercent === 0 ? 0 : parseFloat(dtPercent),
        // downturn distrib
        activate_scenario: activeScenario ? 1 : 0,
        contribution_downturn_endqtr: downturnContribEndQtr || 'Q1',
        contribution_downturn_endyear: parseInt(contribScenarioEndYr) || parseInt(validStartYear),
        contribution_downturn_pct: parseFloat(scenarioContribPercentage) / 100.0,
        distribution_downturn_endqtr: downturnDistribEndQtr || 'Q4',
        distribution_downturn_endyear: parseInt(distribScenarioEndYr) || parseInt(validStartYear),
        distribution_downturn_pct: parseFloat(scenarioDistribPercentage) / 100.0,

    };


    let realEstateOnlyCheck = true;
    assetClassShortNames.forEach((shortName) => {
        const filteredStrategyAllocation = filterStrategyAllocationByAssetClass(
            strategyAllocation,
            shortName
        );
        filteredStrategyAllocation.forEach((datum) => {
            if (shortName !== 'RE') {
                realEstateOnlyCheck = false;
            }
            const stratInfo = targetAllocationStrategies.find(
                ({ strategy }) => strategy === datum.serverNames
            );
            const { alloc, northAmerica, europe } = strategyAllocation.find(
                ({ serverNames }) => serverNames === datum.serverNames
            );

            if (stratInfo) {
                const { pName, rName, uiName, wName } = stratInfo;

                inputs[pName] =
                    targetExposureType === 'noNewCommitments' ? 0 : alloc;
                inputs[rName] = [northAmerica, northAmerica + europe];

                if (uiName.includes('User Defined')) {
                    let propertyName = `sname_${shortName.toLowerCase()}_udef`;

                    switch (uiName) {
                        case 'User Defined 1':
                            propertyName += '1';
                            break;
                        case 'User Defined 2':
                            propertyName += '2';
                            break;
                        case 'User Defined 3':
                            propertyName += '3';
                            break;
                        default:
                            break;
                    }

                    const value = userDefinedRenamedTAS[wName].currentValue;

                    inputs[propertyName] =
                        targetExposureType === 'noNewCommitments' ? 0 : value;
                }

                if (
                    targetExposureType === 'noNewCommitments' &&
                    pName === 'pct_pe_buyout'
                ) {
                    inputs[pName] = 100;
                }
            }
        });
    });
    inputs.REstyleCommits = realEstateOnlyCheck;

    return inputs;
}
